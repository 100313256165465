<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 18:08:20
 * @LastEditTime: 2021-02-03 11:00:04
 * @FilePath: \acuconference-web\src\views\admin\overview.vue
-->
<template>
  <v-card :loading="loading">
    <v-card-title>
      {{$t("label_navigation_menu_list_overview")}}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row v-for="(value, key) in overView" :key="key">
          <v-col cols="12" md="5" class="font-weight-black">
            {{getOverviewKey(key)}}
          </v-col>
          <v-col cols="12" md="3">
            {{getOverviewValue(key, value)}}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import apis from "../../mixins/apis"
export default {
  mixins: [apis],
  created() {
    this.initUserOverview()
  },
  data() {
    return {
      overView: {

      }
    }
  },
  methods: {
    async initUserOverview() {
      const result = await this.getUserOverviewApi()
      if (result) {
        this.overView = result
      }
    },
    getOverviewKey(key) {
      switch (key) {
        case 'domainCount':
          return this.$t("label_normal_Domain") + ':'
        case "userCount":
          return this.$t("label_normal_account") + ':'
        case "roomCount":
          return this.$t("label_conference_rooms") + ":"
        case "turnoverTimeCount":
          return this.$t("label_total_duration_of_conference_hours") + ":"
        case "turnoverTimeMonthCount":
          return this.$t("label_duration_of_conference_hours_this_month") + ":" 
        default:
          break;
      }
    },
    getOverviewValue(key, value) {
      switch (key) {
        case "turnoverTimeCount":
        case "turnoverTimeMonthCount":
          // 毫秒转为小时
          return Math.round(Number(value) / 1000 / 60 / 60)
        default:
          return value
      }
    }
  }
}
</script>

<style lang="sass" scoped>

</style>